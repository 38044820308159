import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            hasBeenCalled: 'user/hasBeenCalled',
            isLoggedIn: 'user/isLoggedIn',
        }),
    },

    async mounted() {
        document.addEventListener('visibilitychange', async () => {
            await this.checkLogin()
        })

        await this.checkLogin()
    },

    methods: {
        async checkLogin() {
            const initialState = this.isLoggedIn
            if (document.visibilityState !== 'visible' || this.hasBeenCalled) return

            if (this.checkCookie() && !initialState) return await this.$store.dispatch('user/checkUser')

            if (!this.checkCookie() && initialState) {
                window.onbeforeunload = null
                return (window.location.href = '/account/logout')
            }

            this.checkPrivateContent()
        },

        checkPrivateContent() {
            if (!this.checkCookie && ['/user/my-profile', '/user/my-documents'].includes(window.location.pathname)) {
                window.location.replace('/')
            }
        },

        checkCookie() {
            return document.cookie.indexOf('brightsprout=') !== -1
        },
    },
}
